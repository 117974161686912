import User from "../types/User";

export const onboarded = (user?: User) => {
  if (user && (user.activeReferee === undefined || !user.firstName)) {
    return false;
  }
  return true;
};

export default (user?: User): string[] => {
  if (!user) return [];

  const missingInformation = [];

  if (!user.firstName) {
    missingInformation.push("first_name");
  }
  if (!user.targetCompanies?.filter((tc) => tc.company && tc.jobUrl)?.length) {
    missingInformation.push("target_companies");
  }

  return missingInformation;
};
